import Footer from '../dashboard/Footer';
import useTranslation from '../../hooks/useTranslation';
import { useIsEmbedded } from '../../hooks/useIsEmbedded';
import useResponsive from '../../hooks/useResponsive';
import Header from '../dashboard/HeaderFree';
import { useSelector } from 'react-redux';
import SidebarFree from '../dashboard/SidebarFree';
import { useCallback, useRef, useState } from 'react';
import { useRouterWithLang } from 'hooks/useRouterWithLang';
import PopupNewsletter from '../../components/generic/PopupNewsletter';
import PopupNewSignup from '../../components/generic/PopupNewSignup';

function Main({ children }) {
  const router = useRouterWithLang();
  const { isMobile, isTablet, isLaptop } = useResponsive();
  const isSmallDevice = isMobile || isTablet;
  const { TRANSLATIONS, translationsParameters } = useTranslation();
  const isEmbedded = useIsEmbedded();
  const [menuOpen, setMenuOpen] = useState(false);
  const menuOpenHandler = useCallback(() => {
    setMenuOpen((prevState) => !prevState);
  }, []);
  const conditionMessage =
    TRANSLATIONS.extraField.message !== null && !isEmbedded;
  const isLoggedIn = useSelector((s) => s.isLoggedIn);
  const showNewSignupPopup = useSelector((s) => s.showNewSignupPopup);
  const mainRef = useRef(null);
  const { lang } = router.query;

  const scrollToMain = useCallback(() => {
    window.scrollTo({
      top: mainRef?.current?.getBoundingClientRect()?.top,
      behavior: 'smooth',
    });
  }, []);

  const isCatalog = router.pathname === '/product';
  return (
    <div>
      {conditionMessage && (
        <div className={`top-banner top-banner-max`}>
          <div className={'top-banner-message-box d-f fd-c ai-c'}>
            <p className={'ta-c'}>
              {translationsParameters(TRANSLATIONS.extraField.message, [
                // eslint-disable-next-line react/jsx-key
                <a
                  href={TRANSLATIONS.extraField.href}
                  rel="noreferrer"
                  key={TRANSLATIONS.extraField.href}
                >
                  {TRANSLATIONS.extraField.cta}
                </a>,
              ])}
            </p>
          </div>
        </div>
      )}
      <div className={`${!isMobile && 'grid-layout'}`}>
        <Header
          isSmallDevice={isSmallDevice}
          isLoggedIn={isLoggedIn}
          menuOpenHandler={menuOpenHandler}
        />
        <SidebarFree
          menuOpen={menuOpen}
          menuOpenHandler={menuOpenHandler}
          publicSection
        />
        <div className={'bg-main'}>
          <main className={`${isSmallDevice ? 'flex fd-c' : ''}`} ref={mainRef}>
            {!isLoggedIn && lang === 'it_IT' && <PopupNewsletter />}
            {showNewSignupPopup && lang === 'it_IT' && <PopupNewSignup />}
            <div className={`content`}>{children}</div>
          </main>
        </div>
        <Footer />
      </div>
      <style jsx>
        {`
          .header-free {
            position: relative;
          }
          .header-free-text {
            position: absolute;
            height: 100%;
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            margin-top: 6vw;
            padding: 0 25px;
            line-height: 28px;
          }
          .header-free h6 {
            font-size: 20px;
            font-weight: 400;
            text-align: center;
            cursor: default;
          }
          .header-free span {
            background: linear-gradient(
              261.96deg,
              #005eff 33.88%,
              #00e4b8 113.8%
            );
            font-weight: 700;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }
          .brands__header {
            padding: 25px;
          }
          @media only screen and (min-width: 490px) {
            .brands__header {
              padding: 40px;
            }
            .header-free {
              max-width: 1440px;
              margin: 20px auto 0 auto;
            }
            .header-free-text {
              position: absolute;
              width: 43%;
              height: 100%;
              display: flex;
              flex-direction: column;
              justify-content: center;
              flex-wrap: wrap;
              margin-left: 80px;
              padding: 0;
              margin-top: 0px;
              line-height: 35px;
            }
            .header-free h6 {
              text-align: left;
              font-size: 26px;
              font-weight: 200;
            }
          }
          @media only screen and (min-width: 768px) {
            .header-free-text {
              line-height: 45px;
            }
            .header-free h6 {
              font-size: 22px;
              line-height: 30px;
            }
          }
          @media only screen and (min-width: 1200px) {
            .header-free-text {
              line-height: 45px;
            }
            .header-free h6 {
              font-size: 38px;
              line-height: 45px;
            }
          }
          .top-banner {
            z-index: 8;
            box-sizing: border-box;
            align-items: center;
            padding: 4px;
            background: var(--banner-top);
            border-bottom: var(--line-header);
          }
          .top-banner-message-box p {
            color: var(--top-banner-p);
            font-size: 13px;
            font-weight: 500;
          }
          .content {
            margin-left: auto;
            margin-right: auto;
            flex-wrap: wrap;
            width: 100%;
            box-sizing: border-box;
          }
          @media only screen and (min-width: 992px) {
            .top-banner {
              z-index: 8;
              box-sizing: border-box;
              align-items: center;
              padding: 0px;
              background: var(--banner-top);
              border-bottom: var(--line-header);
            }
            .top-banner {
              width: 100%;
            }
            .top-banner-message-box p {
              color: var(--top-banner-p);
              font-size: 13px;
              font-weight: 300;
              letter-spacing: 0.5px;
            }
            .top-banner-message-box a {
              color: var(--top-banner-p);
              font-size: 13px;
              font-weight: 700;
              text-decoration: underline;
            }
            .top-banner-message-box {
              padding: 0.4em;
              width: 100%;
            }
            .content {
              max-width: 1440px;
              margin-left: auto;
              margin-right: auto;
              flex-wrap: wrap;
              width: 100%;
              box-sizing: border-box;
            }
          }
          .grid-layout {
            display: grid;
            grid-template-columns: 1fr;
            grid-template-rows: 105px 1fr 600px;
            grid-template-areas:
              'header'
              'main'
              'footer';
            height: 100vh;
          }
          main {
            padding: 16px 8px;
            flex-grow: 1;
            display: flex;
            //max-width: 1440px;
            margin: auto;
            justify-content: center;
          }
          .bg-main {
            background: var(--background-utils);
            grid-area: main;
          }
          @media only screen and (max-width: 768px) {
            main {
              padding: 8px;
              flex-grow: 1;
              display: flex;
              //max-width: 1440px;
              margin: auto;
            }
          }
          main :global(.order-list-page) {
            width: 100% !important;
          }
          .main {
            background: var(--background);
          }
        `}
      </style>
    </div>
  );
}

export default Main;
